import axios from "./axios";

/* 登录 */

// 登录
export function login(params) {
  return axios({
    url: "pwbase/admin/user/login",
    method: "post",
    encrypt: false,
    data: {
      f_ac: params.f_ac,
      f_pass: params.f_pass,
    },
  });
}
// 退出登录
export function logout() {
  return axios({
    url: "pwbase/admin/user/logout",
    method: "post",
    encrypt: false,
  });
}
// 修改密码
export function password(params) {
  return axios({
    url: "pwbase/admin/user/password/modify",
    method: "post",
    data: {
      f_old_password: params.f_old_password,
      f_new_password: params.f_new_password,
    },
  });
}

/* 演出类型 */

// 获取演出类型
export function queryType() {
  return axios({
    url: "pwbase/yc/fl/list",
    method: "post",
    encrypt: false,
  });
}
// 新增/编辑演出类型
export function saveType(params) {
  return axios({
    url: "pwbase/yc/fl/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_name: params.f_name,
      f_sort: params.f_sort,
    },
  });
}
// 删除演出类型
export function deleteType(params) {
  return axios({
    url: "pwbase/yc/fl/del",
    method: "post",
    data: {
      f_id: params.f_id,
    },
  });
}

/* 演出信息 */

// 获取演出信息
export function queryPerform(params) {
  return axios({
    url: "pwbase/sp/xx/list1",
    method: "post",
    encrypt: false,
    data: {
      f_yc_fl: params.f_yc_fl,
      f_a_flag: 1,
    },
  });
}
// 新增/编辑演出信息
export function savePerform(params) {
  return axios({
    url: "pwbase/sp/xx/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_yc_fl: params.f_yc_fl,
      f_name: params.f_name,
      f_dz: params.f_dz,
      f_csBm: params.f_csBm,
      f_csMc: params.f_csMc,
      f_smrz: params.f_smrz,
      f_ycsj: params.f_ycsj,
      f_fm_img: params.f_fm_img,
      f_gpxz_id: params.f_gpxz_id,
      f_gyxz_id: params.f_gyxz_id,
      f_xmxq_id: params.f_xmxq_id,
      f_xxdz: params.f_xxdz,
      f_xg_sl: params.f_xg_sl,
      f_yf: params.f_yf,
      f_qp_fs: params.f_qp_fs,
      f_ys_sj: params.f_ys_sj,
      f_zwt: params.f_zwt,
      f_jd: params.f_jd,
      f_wd: params.f_wd,
      f_jz_sj: params.f_jz_sj,
      f_zt: params.f_zt,
      f_tk_xx: params.f_tk_xx,
      f_xmjs: params.f_xmjs,
      f_xmpt: params.f_xmpt,
      f_tpjzsj: params.f_tpjzsj,
      f_tpkssj: params.f_tpkssj,
      f_sfzs:params.f_sfzs,
    },
  });
}
// 删除演出信息
export function deletePerform(params) {
  return axios({
    url: "pwbase/sp/xx/del",
    method: "post",
    data: {
      f_id: params.f_id,
      f_yc_fl: params.f_yc_fl,
    },
  });
}
// 获取单独演出信息
export function queryPerformOne(params) {
  return axios({
    url: "pwbase/sp/xx/one",
    method: "post",
    encrypt: false,
    data: {
      f_id: params.f_id,
      f_yc_fl: params.f_yc_fl,
      f_a_flag: 1,
    },
  });
}

/* 演出须知 */

// 获取演出须知
export function queryNotice() {
  return axios({
    url: "pwbase/xz/xx/list",
    method: "post",
  });
}
// 新增/编辑演出须知
export function saveNotice(params) {
  return axios({
    url: "pwbase/xz/xx/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_name: params.f_name,
      f_view_name: params.f_view_name,
      f_list: params.f_list,
    },
  });
}
// 删除演出须知
export function deleteNotice(params) {
  return axios({
    url: "pwbase/xz/xx/del",
    method: "post",
    data: {
      f_id: params.f_id,
    },
  });
}

/* 演出分类 */

// 获取演出分类
export function queryClass(params) {
  return axios({
    url: "pwbase/sp/lb/tree",
    method: "post",
    data: {
      f_goods_id: params.f_goods_id,
    },
  });
}
// 新增/编辑演出分类
export function saveClass(params) {
  return axios({
    url: "pwbase/sp/lb/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_name: params.f_name,
      f_p_id: params.f_p_id,
      f_goods_id: params.f_goods_id,
      f_ycfl_id: params.f_ycfl_id,
      f_sort: params.f_sort,
    },
  });
}
// 删除演出分类
export function deleteClass(params) {
  return axios({
    url: "pwbase/sp/lb/del",
    method: "post",
    data: {
      f_id: params.f_id,
      f_goods_id: params.f_goods_id,
    },
  });
}

/* 演出价格 */

// 获取演出价格
export function queryPrice(params) {
  return axios({
    url: "pwbase/sp/jg/tree",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_q_flag: true,
    },
  });
}
// 新增/编辑演出价格
export function savePrice(params) {
  return axios({
    url: "pwbase/sp/jg/save",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_ycfl: params.f_ycfl,
      f_spfl_str: params.f_spfl_str,
      f_spjg: params.f_spjg,
      f_kc: params.f_kc,
      f_sort: params.f_sort,
      f_gyrs: params.f_gyrs,
    },
  });
}
// 删除演出价格
export function deletePrice(params) {
  return axios({
    url: "pwbase/sp/jg/del",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_fl_id: params.f_fl_id,
    },
  });
}
// 获取价格库存
export function queryPriceNumber(params) {
  return axios({
    // url: "pwbase/sp/jg/count",
    url: "pwbase/sp/jg/admin/list",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_kssj: params.f_kssj,
      f_jssj: params.f_jssj,
    },
  });
}
// 获取价格库存操作日志
export function queryPriceNumberLog(params) {
  return axios({
    url: "pwbase/sp/jg/admin/log/list",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_jg_id: params.f_jg_id,
    },
  });
}
// 新增价格库存
export function savePriceNumber(params) {
  return axios({
    url: "pwbase/sp/jg/add/kc",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_kc: params.f_kc,
    },
  });
}
// 减少价格库存
export function deletePriceNumber(params) {
  return axios({
    url: "pwbase/sp/jg/sub/kc",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_kc: params.f_kc,
    },
  });
}
// 获取价格锁定
export function queryPriceLock(params) {
  return axios({
    url: "pwbase/sp/jg/lock/release/kc/log",
    method: "post",
    data: {
      f_id: params.f_id,
    },
  });
}
// 编辑价格锁定
export function savePriceLock(params) {
  return axios({
    url: "pwbase/sp/jg/lock/release/kc",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_type: params.f_type,
      f_num: params.f_num,
    },
  });
}

/* 想看数量 */

// 获取演出想看数量
export function queryWant(params) {
  return axios({
    url: "pwbase/wanna/sea/count/" + params.f_spid,
    method: "post",
  });
}
// 新增/编辑想看数量
export function saveWant(params) {
  return axios({
    url: "pwbase/wanna/sea/add/num",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_num: params.f_num,
    },
  });
}

/* 轮播图 */

// 获取轮播图信息
export function queryCarousel() {
  return axios({
    url: "pwbase/lbt/info/admin/list",
    method: "post",
  });
}
// 新增/编辑轮播图信息
export function saveCarousel(params) {
  return axios({
    url: "pwbase/lbt/info/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_sp_id: params.f_sp_id,
      f_yc_fl_id: params.f_yc_fl_id,
      f_sort: params.f_sort,
      f_ks_sj: params.f_ks_sj,
      f_js_sj: params.f_js_sj,
      f_img: params.f_img,
      f_img_type: params.f_img_type,
      f_fm: params.f_fm,
    },
  });
}
// 删除轮播图信息
export function deleteCarousel(params) {
  return axios({
    url: "pwbase/lbt/info/del",
    method: "post",
    data: {
      f_id: params.f_id,
    },
  });
}

/* 视频信息 */

// 获取视频信息
export function queryVideo(params) {
  return axios({
    url: "pwbase/video/info/list",
    method: "post",
    data: {
      f_xm_pt: params.f_xm_pt,
    },
  });
}
// 新增/编辑视频信息
export function saveVideo(params) {
  return axios({
    url: "pwbase/video/info/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_title: params.f_title,
      f_content: params.f_content,
      f_url: params.f_url,
      f_sp_id: params.f_sp_id,
      f_yc_fl_id: params.f_yc_fl_id,
    },
  });
}
// 删除视频信息
export function deleteVideo(params) {
  return axios({
    url: "pwbase/video/info/del",
    method: "post",
    data: {
      id: params.id,
    },
  });
}

/* 订单信息 */

// 获取订单信息
export function queryOrder(params) {
  return axios({
    url: "pwbase/order/info/admin/list",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_ks_sj: params.f_ks_sj,
      f_js_sj: params.f_js_sj,
      f_u_id: params.f_u_id,
      f_sp_id: params.f_sp_id,
      f_state: params.f_state,
      f_qpfs: params.f_qpfs,
      f_pay_type: params.f_pay_type,
      f_dc_flag: params.f_dc_flag,
      f_sfzh: params.f_sfzh,
      f_name: params.f_name,
      f_pd: params.f_pd,
      f_page_no: params.f_page_no,
      f_page_size: params.f_page_size,
      f_mobile: params.f_mobile,
    },
  });
}
// 更新订单状态
export function updateOrder(params) {
  return axios({
    url: "pwbase/order/info/update/wl",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_wl_mc: params.f_wl_mc,
      f_wl_dh: params.f_wl_dh,
    },
  });
}
// 更改订单地址
export function updateAddress(params) {
  return axios({
    url: "pwbase/order/info/admin/address/admin",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_xxdz: params.f_xxdz,
      f_shr: params.f_shr,
      f_lxdh: params.f_lxdh,
      f_sfvalue: params.f_sfvalue,
      f_csvalue: params.f_csvalue,
      f_qxvalue: params.f_qxvalue,
      f_wl_dh: params.f_wl_dh,
      f_wl_mc: params.f_wl_mc,
    },
  });
}
// 订单退款处理
export function refundOrder(params) {
  return axios({
    url: "pwbase/order/info/refund/confirm",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_r_state: params.f_r_state,
      f_r_je: params.f_r_je,
    },
  });
}
// 订单取消
export function cancelOrder(params) {
  return axios({
    url: "pwbase/order/info/admin/cancel",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
    },
  });
}

/* 微信 */

// 微信推送消息
export function weChatSend(params) {
  return axios({
    url: "pwbase/wx/applet/send/notice",
    method: "post",
    data: {
      f_o_id: params.f_o_id,
      f_t_id: params.f_t_id,
      f_page: params.f_page,
      f_data: params.f_data,
      f_type: params.f_type,
    },
  });
}
// 获取微信二维码
export function queryWeChat() {
  return axios({
    url: "pwbase/wx/query/group/image",
    method: "get",
    encrypt: false,
  });
}
// 新增/编辑微信二维码
export function saveWeChat(params) {
  return axios({
    url: "pwbase/wx/save/group/image",
    method: "post",
    data: {
      f_img: params.f_img,
    },
  });
}

/* 抖音关联 */

// 获取抖音列表
export function queryTiktokList() {
  return axios({
    url: "pwbase/dy/poi/list",
    method: "get",
  });
}
// 获取抖音信息
export function queryTiktok(params) {
  return axios({
    url: "pwbase/dy/qual/search",
    method: "post",
    data: {
      poiId: params.poiId,
      type: params.type,
    },
  });
}
// 新增/编辑抖音信息
export function saveTiktok(params) {
  return axios({
    url: "pwbase/dy/goods/upload",
    method: "post",
    data: {
      xkId: params.xkId,
      sqId: params.sqId,
      ycFlId: params.ycFlId,
      spId: params.spId,
      poiId: params.poiId,
      f_t_num: params.f_t_num,
    },
  });
}
// 上/下架抖音信息
export function upOrDownTiktok(params) {
  return axios({
    url: "pwbase/dy/product/operate",
    method: "post",
    data: {
      ycFlId: params.ycFlId,
      spId: params.spId,
      f_id: params.f_id,
    },
  });
}

/* 宣传信息 */

// 获取宣传信息
export function queryPublicize(params) {
  return axios({
    url: "pwbase/tw/info/list",
    method: "post",
    data: {
      f_xm_pt: params.f_xm_pt
    },
    encrypt: false,
  });
}
// 新增/编辑宣传信息
export function savePublicize(params) {
  return axios({
    url: "pwbase/tw/info/save",
    method: "post",
    data: {
      f_id: params.f_id,
      f_title: params.f_title,
      f_content: params.f_content,
      f_sp_id: params.f_sp_id,
      f_sp_name: params.f_sp_name,
      f_yc_fl_id: params.f_yc_fl_id,
      f_fm_img: params.f_fm_img,
      f_zt: params.f_zt,
      f_sort: params.f_sort,
    },
  });
}
// 删除宣传信息
export function deletePublicize(params) {
  return axios({
    url: "pwbase/tw/info/del",
    method: "post",
    data: {
      id: params.id,
    },
  });
}

/* 佣金 */

// 新增/编辑佣金信息
export function saveCommission(params) {
  return axios({
    url: "pwbase/sp/xx/common/plan",
    method: "post",
    data: {
      f_yc_fl: params.f_yc_fl,
      f_sp_id: params.f_sp_id,
      f_rate: params.f_rate,
    },
  });
}
// 新增/编辑佣金状态信息
export function saveCommissionStatus(params) {
  return axios({
    url: "pwbase/sp/xx/common/plan/status",
    method: "post",
    data: {
      f_yc_fl: params.f_yc_fl,
      f_sp_id: params.f_sp_id,
      f_state: params.f_state,
    },
  });
}
// 获取佣金计划信息
export function queryCommissionPlan(params) {
  return axios({
    url: "pwbase/sp/xx/common/oriented/plan/list",
    method: "post",
    data: {
      f_yc_fl: params.f_yc_fl,
      f_sp_id: params.f_sp_id,
    },
  });
}
// 新增/编辑佣金状态信息
export function saveCommissionPlan(params) {
  return axios({
    url: "pwbase/sp/xx/common/oriented/plan/add",
    method: "post",
    data: {
      f_yc_fl: params.f_yc_fl,
      f_sp_id: params.f_sp_id,
      f_type: params.f_type,
      f_rate: params.f_rate,
      merchant_phone: params.merchant_phone,
      douyin_id_list: params.douyin_id_list,
      commission_duration: params.commission_duration,
      start_time: params.start_time,
      end_time: params.end_time,
      f_plan_id: params.f_plan_id,
      f_drmc: params.f_drmc,
    },
  });
}
// 删除佣金状态信息
export function deleteCommissionPlan(params) {
  return axios({
    url: "pwbase/sp/xx/common/oriented/plan/status",
    method: "post",
    data: {
      f_plan_id: params.f_plan_id,
    },
  });
}
// 获取佣金金额信息
export function queryCommissionAmount(params) {
  return axios({
    url: "pwbase/sp/xx/common/oriented/plan/query/dr",
    method: "post",
    data: {
      plan_id: params.plan_id,
      douyin_id_list: params.douyin_id_list,
    },
  });
}

/* 分账 */

// 获取分账信息
export function queryProfit(params) {
  return axios({
    url: "pwbase/order/tj/settle/info",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_pay_type: params.f_pay_type,
    },
  });
}


/* 数据 */

// 获取数据信息
export function queryDate(params) {
  return axios({
    url: "pwbase/order/tj/info",
    method: "post",
    data: {
      f_xm_ids: params.f_xm_ids,
      f_ks_sj: params.f_ks_sj,
      f_js_sj: params.f_js_sj,
    },
  });
}

/* 短信 */

// 新增短信信息
export function saveMessage(params) {
  return axios({
    url: "pwbase/send/order/notice",
    method: "post",
    data: {
      f_sp_id: params.f_sp_id,
      f_yc_cs: params.f_yc_cs,
      f_state: params.f_state,
      f_yc_fl: params.f_yc_fl,
      f_yc_sj: params.f_yc_sj,
      f_cp_zq: params.f_cp_zq,
    },
  });
}

/* 省市区 */

// 获取省市区
export function queryArea() {
  return axios({
    url: "pwbase/provinces/cities/areas",
    method: "get",
    encrypt: false,
  });
}

/* 上传 */

// 上传文件
export function uploadFile(params) {
  return axios({
    url: "https://gc.njyfkj.cn/upload",
    method: "post",
    data: params,
    encrypt: false,
  });
}
